/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Vinston Guillaume",
  description:
    "Software Engineer",
  og: {
    title: "Vinston Guillaume",
    type: "website",
    url: "http://vinstonguillaume.com.com/",
  },
};

//Home Page
const greeting = {
  title: "Vinston Guillaume",
  logo_name: "VinstonGuillaume",
  nickname: "Vince",
  subTitle:
    "Software Engineer",
  resumeLink:
    "",
  portfolio_repository: "https://github.com/vguillaume8",
};

const socialMediaLinks = [
  // {
  //   name: "Github",
  //   link: "https://github.com/vguillaume8",
  //   fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
  //   backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  // },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/vinstonguillaume/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "Gmail",
    link: "mailto:vinstonguillaume@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  }
];

const skills = {
  data: [
    {
      title: "Software Engineering",
      fileName: "DataScienceImg",
      skills: [
        "⚡ Experience in building Full-Stack Applications",
        "⚡ Expereince in building Mobile Applications for IOS and Android",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "Swift",
          fontAwesomeClassname: "simple-icons:swift",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "Java",
          fontAwesomeClassname: "simple-icons:java",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "Vue",
          fontAwesomeClassname: "simple-icons:vuejs",
          style: {
            color: "#339933",
          },
        },
      ],
    },
    {
      title: "Dev-Ops",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Experience working on multiple cloud platforms",
        "⚡ Experience in building tools/applications on top of multiple cloud platforms"
      ],
      softwareSkills: [
        {
          skillName: "GCP",
          fontAwesomeClassname: "simple-icons:googlecloud",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "Kubernetes",
          fontAwesomeClassname: "simple-icons:kubernetes",
          style: {
            color: "#326CE5",
          },
        },
      ],
    }
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    
  ],
};

const degrees = {
  degrees: [
    {
      title: "State University of New York at New Paltz",
      subtitle: "Bachelor of Science in Computer Science",
      logo_path: "newpaltzlogo.jpeg",
      alt_name: "SUNY New Paltz",
      duration: "2015 - 2019",
      descriptions: [
        "🏆 Awards: 1st Place in SUNY New Paltz Computer Science Senior Project Expo (Spring 2019), AMP/CSTEP Most Outstanding Scholar (Spring 2019), Dean’s List: Spring 2016, Spring/Fall 2018, Spring 2019",
        "📚 Relevant Coursework: Software Engineering, Web Programming, Object Oriented Programing, Digital Logics,Assembly/Computer Architecture, Operating Systems, Discrete Mathematics for Computing, Discrete & Continuous Algorithms, Calculus II",
      ],
      website_link: "https://www.newpaltz.edu/",
    },
  ],
};

const certifications = {
  certifications: [
  //   {
  //     title: "Machine Learning",
  //     subtitle: "- Andrew Ng",
  //     logo_path: "stanford_logo.png",
  //     certificate_link:
  //       "https://www.coursera.org/account/accomplishments/verify/22MTSSC5WDTM",
  //     alt_name: "Stanford University",
  //     color_code: "#8C151599",
  //   },
  //   {
  //     title: "Deep Learning",
  //     subtitle: "- Andrew Ng",
  //     logo_path: "deeplearning_ai_logo.png",
  //     certificate_link:
  //       "https://www.coursera.org/account/accomplishments/specialization/H8CPSFXAJD2G",
  //     alt_name: "deeplearning.ai",
  //     color_code: "#00000099",
  //   },
  //   {
  //     title: "ML on GCP",
  //     subtitle: "- GCP Training",
  //     logo_path: "google_logo.png",
  //     certificate_link:
  //       "https://www.coursera.org/account/accomplishments/specialization/EB4VJARK8647",
  //     alt_name: "Google",
  //     color_code: "#0C9D5899",
  //   },
  //   {
  //     title: "Data Science",
  //     subtitle: "- Alex Aklson",
  //     logo_path: "ibm_logo.png",
  //     certificate_link:
  //       "https://www.coursera.org/account/accomplishments/specialization/PLEAPCSJBZT5",
  //     alt_name: "IBM",
  //     color_code: "#1F70C199",
  //   },
  //   {
  //     title: "Big Data",
  //     subtitle: "- Kim Akers",
  //     logo_path: "microsoft_logo.png",
  //     certificate_link:
  //       "https://drive.google.com/file/d/164zKCFOsI4vGqokc-Qj-e_D00kLDHIrG/view",
  //     alt_name: "Microsoft",
  //     color_code: "#D83B0199",
  //   },
  //   {
  //     title: "Advanced Data Science",
  //     subtitle: "- Romeo Kienzler",
  //     logo_path: "ibm_logo.png",
  //     certificate_link:
  //       "https://www.coursera.org/account/accomplishments/verify/BH2T9BRU87BH",
  //     alt_name: "IBM",
  //     color_code: "#1F70C199",
  //   },
  //   {
  //     title: "Advanced ML on GCP",
  //     subtitle: "- GCP Training",
  //     logo_path: "google_logo.png",
  //     certificate_link:
  //       "https://www.coursera.org/account/accomplishments/verify/5JZZM7TNQ2AV",
  //     alt_name: "Google",
  //     color_code: "#0C9D5899",
  //   },
  //   {
  //     title: "DL on Tensorflow",
  //     subtitle: "- Laurence Moroney",
  //     logo_path: "deeplearning_ai_logo.png",
  //     certificate_link:
  //       "https://www.coursera.org/account/accomplishments/verify/6T4DCUGNK8J8",
  //     alt_name: "deeplearning.ai",
  //     color_code: "#00000099",
  //   },
  //   {
  //     title: "Fullstack Development",
  //     subtitle: "- Jogesh Muppala",
  //     logo_path: "coursera_logo.png",
  //     certificate_link:
  //       "https://www.coursera.org/account/accomplishments/certificate/NRANJA66Y2YA",
  //     alt_name: "Coursera",
  //     color_code: "#2A73CC",
  //   },
  //   {
  //     title: "Kuberenetes on GCP",
  //     subtitle: "- Qwiklabs",
  //     logo_path: "gcp_logo.png",
  //     certificate_link:
  //       "https://google.qwiklabs.com/public_profiles/e4d5a92b-faf6-4679-a70b-a9047c0cd750",
  //     alt_name: "GCP",
  //     color_code: "#4285F499",
  //   },
  //   {
  //     title: "Cryptography",
  //     subtitle: "- Saurabh Mukhopadhyay",
  //     logo_path: "nptel_logo.png",
  //     certificate_link:
  //       "https://drive.google.com/open?id=1z5ExD_QJVdU0slLkp8CBqSF3-C3g-ro_",
  //     alt_name: "NPTEL",
  //     color_code: "#FFBB0099",
  //   },
  //   {
  //     title: "Cloud Architecture",
  //     subtitle: "- Qwiklabs",
  //     logo_path: "gcp_logo.png",
  //     certificate_link:
  //       "https://google.qwiklabs.com/public_profiles/5fab4b2d-be6f-408c-8dcb-6d3b58ecb4a2",
  //     alt_name: "GCP",
  //     color_code: "#4285F499",
  //   },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "These are the places that I've worked & volunteered at",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      experiences: [
        {
          title: "Software Engineer II",
          company: "Twilio",
          company_url: "https://www.twilio.com/",
          logo_path: "twilio-mark-red.png",
          duration: "August 2020 - PRESENT",
          location: "San Francisco, California",
          description:
            "Working under Twilio’s Platform Engineering that provides PaaS (platform as a service) to all engineers at Twilio.",
          color: "#0879bf",
        },
        {
          title: "Software Engineer I",
          company: "Twilio",
          company_url: "https://www.twilio.com/",
          logo_path: "twilio-mark-red.png",
          duration: "July 2019 - August 2020",
          location: "San Francisco, California",
          description:
            "Working under Twilio’s Platform Engineering that provides PaaS (platform as a service) to all engineers at Twilio.",
          color: "#9b1578",
        },
        {
          title: "Software Engineer",
          company: " SUNY New Paltz Educational Opportunity (EOP)",
          company_url: "https://www.newpaltz.edu/eop/",
          logo_path: "newpaltz.png",
          duration: "January 2019 - May 2019",
          location: "New Paltz, NY",
          description:
            "Created an Online Tracking system for advisors to monitor their prospective students.",
          color: "#fc1f20",
        },
      ],
    },
    {
      title: "Internships",
      experiences: [
        {
          title: "Software Engineer Intern",
          company: "HBO",
          company_url: "https://www.hbo.com/",
          logo_path: "hbo.png",
          duration: "June 2018 - August 2018",
          location: "New York, NY",
          description:
            "Created a full stack dashboard in React.js/Python/PostgreSQL using AWS services to display workflow tasks for the Media Technology Information System (MTIS) team.",
          color: "#ee3c26",
        }
      ],
    },
    {
      title: "Volunteerships",
      experiences: [
        {
          title: "Mentor",
          company: "ColorStack",
          company_url: "https://www.colorstack.org/",
          logo_path: "logo_colorstack.png",
          duration: "August 2021 - PRESENT",
          location: "New York, NY",
          description:
            "Mentored fellows apart of ColorStack's Sprout Program.",
          color: "#4285F4",
        },
        {
          title: "Mentor",
          company: "Code 2040",
          company_url: "http://www.code2040.org/",
          logo_path: "code2040.jpeg",
          duration: "June 2021 - PRESENT",
          location: "New York, NY",
          description:
            "Mentored fellows apart of Code 2040's summer internship program.",
          color: "#D83B01",
        },
        {
          title: "Mentor",
          company: "T. Howard Foundation",
          company_url: "https://www.t-howard.org/",
          logo_path: "t-howard.jpeg",
          duration: "June 2021 - PRESENT",
          location: "New York, NY",
          description:
            "Mentored fellows apart of T. Howard Foundation summer internship program. ",
          color: "#000000",
        },
        {
          title: "Tech Assistant",
          company: "Black Girl Code",
          company_url:
            "https://www.blackgirlscode.com/",
          logo_path: "black_girl_code.jpeg",
          duration: "June 2018 - PRESENT",
          location: "New York, NY",
          description:
            "Assisted tech instructors, answered student questions, and helped students understand coding concepts.",
          color: "#0C9D58",
        }
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "A few of my favorite public projects",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Websites",
  description:
    "A few of my projects that are on the Web",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "animated_ashutosh.png",
    description:
      "I am available for contact via LinkedIn and Email.",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "For individual fundamental empowerment, I like to write powerful lessons that create impact on each of the reader individually to change the core of their character.",
    link: "https://ashutoshhathidara.wordpress.com",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle:
      "Ambavadi vas, Kanodar, T.A.-Palanpur, Dist.-Banaskantha, Gujarat - 385520",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://goo.gl/maps/MpMqtRZytFchMkZ76",
  },
  phoneSection: {
    title: "Phone Number",
    subtitle: "+91 8320758513",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  contactPageData,
};
